import { Image, Text } from '@ui/atoms';
import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import LazyImage from '@atoms/LazyImage';

const BrandingCard = React.memo(
    ({ brand, setSelectedBrand, selectedBrand, showFaq }) => {
        const { id, title, logo, description, topLevelDetails, faqs, bgColor } =
            brand;
        const isBrandSelected = selectedBrand === id;
        return (
            <motion.div
                className={`flex flex-col w-full border border-gray-200 shadow-sm xl:overflow-hidden  rounded-xl`}
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
                id={id}
            >
                <div className="flex flex-col w-full border-gray-200 xl:flex-row xl:border-b ">
                    <div
                        className={`relative xl:w-[214px] rounded-t-xl xl:rounded-none flex items-center justify-center pb-3 border border-gray-100 pt-7 xl:py-[52px] xl:px-8 xl:border-0 ${bgColor} `}
                    >
                        <LazyImage
                            src={logo}
                            alt
                            className="absolute w-full h-8 transform -translate-x-1/2 left-1/2 -top-4 xl:relative xl:top-0 xl:translate-x-0 xl:left-auto xl:transform-none"
                        />
                        <Text
                            content={title}
                            className="text-gray-900 p7-medium xl:hidden"
                        />
                    </div>
                    <div className="flex flex-col items-center gap-2 p-3 rounded-b-xl xl:rounded-none xl:px-6 xl:py-0 xl:items-start xl:justify-center xl:w-full xl:gap-4 bg-basicWhite">
                        <div className="flex flex-col gap-2 p4-medium">
                            <Text
                                content={title}
                                className="hidden text-primary-500 p4-medium xl:inline-block"
                                htmlTag='h3'
                            />
                            <Text
                                content={description}
                                className={`text-center ${showFaq ? 'border-b pb-2 xl:border-b-0 xl:pb-0 border-gray-200' : '' } xl:text-left text-basicBlack p5-regular`}
                            />
                        </div>
                        <div className="flex items-center justify-between xl:gap-14 xl:w-full">
                            <div className="items-center hidden gap-12 xl:inline-flex">
                                {topLevelDetails.map((detail) => {
                                    const { label, value } = detail;
                                    return (
                                        <div
                                            className="flex items-center gap-2 p5-medium"
                                            key={label}
                                        >
                                            <Text
                                                content={`${label}:`}
                                                className="text-primary-500 "
                                            />
                                            <Text
                                                content={value}
                                                className="text-basicBlack"
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {showFaq ? (
                                <div
                                    className="flex items-center gap-1 cursor-pointer"
                                    onClick={() => {
                                        isBrandSelected
                                            ? setSelectedBrand(null)
                                            : setSelectedBrand(id);
                                    }}
                                >
                                    <Text
                                        content={`${
                                            isBrandSelected
                                                ? 'See Less'
                                                : 'Learn More'
                                        }`}
                                        className="text-primary-500 p5-medium"
                                    />
                                    <Image
                                        className={`w-4 h-4 transform transition-all ease-in-out ${
                                            isBrandSelected ? '' : 'rotate-180'
                                        }`}
                                        src="/images/arrow-down-primary.svg"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <AnimatePresence key={id} exitBeforeEnter>
                    {isBrandSelected && (
                        <BrandFaqsSection
                            topLevelDetails={topLevelDetails}
                            faqs={faqs}
                        />
                    )}
                </AnimatePresence>
            </motion.div>
        );
    }
);

const BrandFaqsSection = React.memo(({ topLevelDetails, faqs }) => {
    const [expandedFaq, setExpandedFaq] = useState(null);

    return (
        <motion.div
            className="flex flex-col w-full px-4 pb-0 cursor-pointer bg-basicWhite"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
            <div className="inline-flex flex-col gap-2 py-4 border-t border-b border-gray-200 xl:hidden">
                {topLevelDetails.map((detail) => {
                    const { label, value } = detail;
                    return (
                        <div className="" key={label}>
                            <Text
                                content={`${label}:`}
                                className="inline text-primary-500 p5-semibold"
                            />{' '}
                            <Text
                                content={value}
                                className="inline text-basicBlack p5-medium "
                            />
                        </div>
                    );
                })}
            </div>
            <div className={`flex flex-col`}>
                {faqs.map((faq) => {
                    const id = faq?.id;
                    return (
                        <BrandFaqCard
                            key={faq?.id}
                            isExpanded={expandedFaq === id}
                            onToggle={() =>
                                setExpandedFaq(expandedFaq === id ? null : id)
                            }
                            faq={faq}
                        />
                    );
                })}
            </div>
        </motion.div>
    );
});

const BrandFaqCard = React.memo(({ isExpanded, onToggle, faq }) => {
    const { id, question, answer } = faq;
    return (
        <div className="flex flex-col gap-2 py-4 border-b last:border-b-0">
            <div
                onClick={onToggle}
                className="flex items-center justify-between gap-3 "
            >
                <Text
                    content={question}
                    className={`flex-1 ${
                        isExpanded
                            ? 'text-primary-500 p5-semibold'
                            : 'text-gray-600 p5-medium '
                    }`}
                />
                <div onClick={onToggle} className="!w-6 !h-6 self-start">
                    {isExpanded ? (
                        <Image
                            src="/images/arrow-down-primary.svg"
                            className="w-6 aspect-square"
                        />
                    ) : (
                        <Image
                            src="/images/arrow-down-gray.svg"
                            className="w-6 aspect-square"
                        />
                    )}
                </div>
            </div>
            <AnimatePresence key={id} exitBeforeEnter>
                {isExpanded && (
                    <motion.div
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -20, opacity: 0 }}
                        transition={{ duration: 0.2, ease: 'easeInOut' }}
                        className="flex flex-col gap-2 p5-medium text-primary-500"
                    >
                        {answer}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
});

export const InvoiceProductBrandInfo = ({ invoiceProductInfo, showFaq = true }) => {
    const { title, brands } = invoiceProductInfo || {};
    const router = useRouter();
    const hashId = router.asPath.split('#')?.[1] || null;
    const [selectedBrand, setSelectedBrand] = useState(hashId);

    return (
        <motion.div
            className="flex flex-col gap-12 px-4 pt-6 pb-12 md:px-6 w-full md:py-16 xl:px-0 xl:py-10 md:gap-16 xl:gap-8 max-w-[1136px] xl:mx-auto"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
            <Text
                content={title}
                className="text-center h5-regular text-basicBlack md:h3-regular"
                htmlTag='h2'
            />
            <motion.div
                className="flex flex-col w-full gap-12"
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
                {brands.map((brand) => {
                    return (
                        <BrandingCard
                            brand={brand}
                            key={brand?.id}
                            selectedBrand={selectedBrand}
                            setSelectedBrand={setSelectedBrand}
                            showFaq={showFaq}
                        />
                    );
                })}
            </motion.div>
        </motion.div>
    );
};